
import { Plus, Minus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { setupScrollAnimations } from '#app/utils/animations';

interface FAQItemProps {
  question: string;
  answer: string;
  isOpen: boolean;
  toggleOpen: () => void;
}

const FAQItem = ({ question, answer, isOpen, toggleOpen }: FAQItemProps) => {
  return (
    <div className="border-b border-gray-200 dark:border-gray-800 py-5">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={toggleOpen}
        aria-expanded={isOpen}
      >
        <h3 className="text-lg font-medium">{question}</h3>
        <div className="flex-shrink-0 ml-4">
          {isOpen ? (
            <Minus className="h-5 w-5 text-primary" />
          ) : (
            <Plus className="h-5 w-5 text-primary" />
          )}
        </div>
      </button>
      <div
        className={`mt-2 transition-all duration-300 overflow-hidden ${
          isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <p className="text-foreground/70">{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  useEffect(() => {
    setupScrollAnimations();
  }, []);

  const [openItem, setOpenItem] = useState<number | null>(0);

  const toggleItem = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqItems = [
    {
      question: "Quanto tempo em média leva para vender um veículo elétrico na Carregados?",
      answer: "O tempo médio de venda varia de acordo com diversos fatores, incluindo modelo, preço, estado e localização do veículo. Em nossa plataforma, veículos elétricos populares bem precificados costumam ser vendidos entre 3 e 4 semanas. Veículos premium ou raros podem levar um pouco mais de tempo para encontrar o comprador ideal."
    },
    {
      question: "Como são cobradas as taxas de anúncio?",
      answer: "Trabalhamos com um modelo de assinatura mensal ou por anúncio. Você pode escolher entre nossos planos Básico, Empresarial, com diferentes níveis de recursos e número de anúncios. As cobranças são realizadas no início de cada período e você pode cancelar a qualquer momento. Não cobramos comissões sobre a venda."
    },
    // {
    //   question: "Vocês verificam os veículos antes de anunciar?",
    //   answer: "Embora não realizemos inspeções físicas, implementamos um rigoroso processo de verificação de documentação e informações fornecidas. Exigimos fotos detalhadas, histórico de manutenção e informações precisas sobre a bateria. Incentivamos os compradores a realizarem inspeções independentes antes da compra."
    // },
    {
      question: "Como funciona o processo de destaque na página inicial?",
      answer: "Os destaques na página inicial estão disponíveis para assinantes dos planos Básico e Empresarial. Os anúncios são posicionados com base em um algoritmo de rotação equitativa, garantindo que todos os anúncios elegíveis recebam exposição. Os planos Empresariais recebem prioridade de destaque em horários de pico."
    },
    {
      question: "É possível promover concessionárias inteiras na plataforma?",
      answer: "Sim! Nosso plano Empresarial foi desenvolvido especificamente para concessionárias e revendedores de grande porte. Ele inclui a criação de um perfil completo da concessionária, integração com sistemas de CRM, API para sincronização automática de estoque e um gerente de conta dedicado para auxiliar em todas as etapas."
    },
    // {
    //   question: "Como vocês ajudam a determinar o valor justo para um veículo elétrico?",
    //   answer: "Oferecemos ferramentas especializadas de avaliação que consideram fatores específicos de veículos elétricos, como degradação da bateria, atualizações de software instaladas e equipamentos opcionais. Também fornecemos dados de mercado atualizados e histórico de preços para modelos similares na plataforma."
    // }
  ];

  return (
    <section id="faq" className="section bg-gray-50 dark:bg-gray-900/50">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-20 left-10 w-72 h-72 bg-blue-100 rounded-full opacity-30 blur-3xl"></div>
        <div className="absolute bottom-20 right-10 w-72 h-72 bg-primary/10 rounded-full opacity-30 blur-3xl"></div>
      </div>
      
      <div className="relative z-10 mb-12 text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
          <span>Perguntas Frequentes</span>
        </div>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Tire suas dúvidas</h2>
        <p className="text-foreground/70 max-w-2xl mx-auto">
          Respondemos às perguntas mais comuns sobre o processo de venda de veículos elétricos em nossa plataforma.
        </p>
      </div>

      <div className="max-w-3xl mx-auto bg-white dark:bg-gray-900 rounded-xl shadow-lg p-6">
        {faqItems.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={openItem === index}
            toggleOpen={() => toggleItem(index)}
          />
        ))}
      </div>

      <div className="animate-on-scroll mt-12 text-center">
        <p className="text-foreground/70 mb-4">Não encontrou o que procurava?</p>
        <a 
          href="mailto:contato@carregados.com.br" 
          className="inline-flex items-center justify-center px-6 py-3 rounded-full bg-primary text-white font-medium transition-all hover:bg-primary/90 shadow-md hover:shadow-lg"
        >
          Entre em Contato
        </a>
      </div>
    </section>
  );
};

export default FAQ;